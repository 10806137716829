import React from "react"
import { Link } from "gatsby"

import styles from "./navigation.module.css"

const NavItem = ({ voice }) =>
  voice.navItemUrl.externalContent ? (
    <a href={voice.navItemUrl.linkUrl} target="_blank" rel="noreferrer">
      {voice.text}
    </a>
  ) : (
    <Link to={voice.navItemUrl.linkUrl} activeClassName={styles.active}>
      <div>{voice.text}</div>
    </Link>
  )

const Navigation = ({ items, locale }) => {
  const menu = items.find(x => x.title === `mainmenu-${locale}`)
  return (
    <div className={styles.navigation}>
      {menu &&
        menu.navItems.map(item => (
          <div key={item._key}>
            <NavItem voice={item} />
          </div>
        ))}
    </div>
  )
}

export default Navigation
