import locales from "../config/locales"

const keys = {}
locales.forEach(locale => {
  keys[locale.code] = locale
})

const getLocale = locale => (keys[locale] || locales[0]).path
const localized = (locale, path) => `${getLocale(locale)}/${path}`
export const pageUrl = (locale, slug) => localized(locale, slug)
export const homeUrl = locale => localized(locale, "")
export const projectUrl = (locale, project) =>
  localized(locale, `project/${project.slug.current}`)
export const pressUrl = (locale, press) =>
  localized(locale, `press/${press.slug.current}`)
