import { Link } from "gatsby"
import React from "react"

import logoSrc from "../images/logo.svg"
import quoteSrc from "../images/menu.svg"
import styles from "./mobile-header.module.css"
import { homeUrl } from "../utils/url"

const MobileHeader = ({ locale, open }) => (
  <header className={styles.header}>
    <Link to={homeUrl(locale)}>
      <img src={logoSrc} alt="NCA Logo" />
    </Link>
    <img src={quoteSrc} alt="Quote menu" onClick={open} />
  </header>
)

export default MobileHeader
