import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import "./layout.css"
import styles from "./layout.module.css"
import Navigation from "./navigation"
import MobileHeader from "../components/mobile-header"
import { homeUrl } from "../utils/url"
import quoteSrc from "../images/menu.svg"
import logoSrc from "../images/logo.svg"
import faviconSrc from "../images/favicon.ico"

/*
sanityNav(title: { eq: $title }) {
      ...CommonSanityNav
    }
*/

const SideMenu = ({ isOpen, mobileHeader, data, locale, close }) => {
  const node = useRef()
  useEffect(() => {
    const handleClick = e => {
      if (node.current.contains(e.target)) {
        return
      }
      close()
    }
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [close])
  return (
    <div ref={node} className={isOpen ? styles.sideOpen : styles.sideClosed}>
      <header
        className={mobileHeader ? styles.fixedHead : styles.fixedHeadMobile}
      >
        <Link to={homeUrl(locale)} className={styles.logoContainer}>
          <img className={styles.logo} src={logoSrc} alt="NCA Logo" />
        </Link>
        <div className={styles.handler}>
          <img src={quoteSrc} onClick={close} alt="NCA menu" />
        </div>
        <Navigation
          items={data.allSanityNav.nodes}
          locale={locale}
        ></Navigation>
      </header>
      <footer className={styles.fixedFoot}>
        <div className={styles.lang}>
          <div>
            <Link to="/" activeClassName={styles.active}>
              Ita
            </Link>
          </div>
          <div>•</div>
          <div>
            <Link to="/en" activeClassName={styles.active}>
              Eng
            </Link>
          </div>
        </div>
        <div className={styles.copy}>
          <div>
            <span className={styles.year}>2020 © </span>Nicole Cieri Architects
          </div>
          <div>
            <small>ALL RIGHTS RESERVED</small>
          </div>
        </div>
      </footer>
    </div>
  )
}

const Layout = ({ children, locale, mobileHeader = true }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityNav {
        nodes {
          title
          navItems {
            text
            _type
            _key
            navItemUrl {
              _key
              _type
              externalContent
              linkUrl
            }
          }
        }
      }
    }
  `)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const isOpen = isOpenMenu || !mobileHeader

  return (
    <div className={styles.layout}>
      <Helmet>
        <script type="text/javascript">
          {`
            var _iub = _iub || [];
            _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"it","siteId":1952181,"cookiePolicyId":83287405, "banner":{ "rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","position":"bottom","textColor":"#010101","backgroundColor":"#a3948c" }};
          `}
        </script>
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          charset="UTF-8"
          async
        ></script>
        <link rel="icon" type="image/png" sizes="16x16" href={faviconSrc} />
      </Helmet>
      <main className={styles.main}>
        {mobileHeader && (
          <MobileHeader locale={locale} open={() => setIsOpenMenu(true)} />
        )}
        {children}
      </main>
      <SideMenu
        isOpen={isOpen}
        mobileHeader={mobileHeader}
        data={data}
        locale={locale}
        close={() => setIsOpenMenu(false)}
      />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
